<style lang="scss" scoped>
.upload-tip {
  color: #e6a23c;
  font-size: 12px;
}
</style>

<template>
  <!-- 用户新增/编辑 -->
  <section class="page-child-contianer">
    <el-form
      class="page-form"
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="84px"
    >
      <el-form-item label="标题:" prop="name">
        <el-input v-model="formData.name" placeholder="请输标题" />
      </el-form-item>

      <el-form-item label="发送范围:" prop="project_ids">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group v-model="checkedProject" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in projectList" :label="item.id" :key="item.id">
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="责任部门:" prop="res_branch_arr">
        <el-select v-model="formData.res_branch_arr" multiple placeholder="请选择">
          <el-option
            v-for="item in branchList"
            :key="item.id"
            :label="item.name"
            :value="item.id + ''"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="是否幻灯:" prop="is_recommend">
        <el-radio-group v-model="formData.is_recommend">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="封面图:">
        <el-upload
          class="img-uploader"
          action="/index/uploadAnnex"
          name="files[]"
          :headers="{
            token: uploadToken,
          }"
          accept=".jpg,.jpeg,.png,.gif"
          :show-file-list="false"
          :before-upload="uploadBefore"
          :on-error="uploadError"
          :on-success="uploadSuccess"
        >
          <img v-if="imageUrl" :src="imageUrl" class="img" />
          <i
            style="font-size: 28px; color: #8c939d; margin-left: 35px"
            v-else
            class="el-icon-plus avatar-uploader-icon"
          ></i>
          <el-input type="hidden" v-model="formData.thumb" prop="thumb" />
        </el-upload>
        <span class="upload-tip"> 建议尺寸686x300(px)</span>
      </el-form-item>

      <el-form-item label="平台位置:" prop="address">
        <el-radio-group v-model="formData.address">
          <el-radio :label="1">员工端</el-radio>
          <el-radio :label="2">业主端</el-radio>
          <el-radio :label="3">所有端</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="状态:" prop="status">
        <el-radio-group v-model="formData.status">
          <el-radio :label="1">发布</el-radio>
          <el-radio :label="2">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="editorcom" label="富文本:">
        <ueditorcom ref="editor1" idName="editor1" />
      </el-form-item>
      <el-form-item>
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="handleSubmit"> 确 定 </el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { validPhone } from "@utils/validate"; //引入手机号码校验规则
import ueditorcom from "@/components/ueditorcom";
import { announcementUpdate, announcementAdd } from "@api/announcement";
import { getProjectList } from "@api/project";
export default {
  name: "announcementForm",
  inject: ["reload"],
  /**
   * 组件
   */
  components: {
    ueditorcom,
  },
  data() {
    // 校验手机号码
    let phoneValidator = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!validPhone(value)) {
          callback(new Error("手机号码格式错误！"));
        }
        callback();
      }
    };
    return {
      id: null, //修改用户id
      imageUrl: "", //图片上传成功回显链接
      formData: {
        address: 3,
        checkList: [],
        status: 1,
        is_recommend: 2,
        project_ids: [],
      }, //表单存储数据
      checkAll: false,
      checkedProject: [],
      checkedProjectTemp: [],
      isIndeterminate: true,
      projectList: [],
      projectListIdsArr: [],

      branchList: [],
      rules: {
        name: [{ required: true, message: "请输入员工姓名", trigger: "blur" }],
      }, //表单校验规则
    };
  },
  computed: {
    ...mapState("system", ["companyListSearch", "branchListSearch"]),
  },
  async created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getDetail();
    }

    let res2 = await getProjectList();
    this.projectList = res2.data;
    if (this.projectList) {
      this.projectList.map((item) => {
        this.projectListIdsArr.push(item.id);
      });
    }
    this.branchList = this.branchListSearch;

    console.log(this.formData.res_branch_arr2);
  },
  mounted() {
    this.uploadToken = sessionStorage.getItem("token");
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedProject = val ? this.projectListIdsArr : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.projectList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.projectList.length;
    },
    /** 修改前获取详情信息 */
    async getDetail() {
      /**
       * 请求接口获取详情数据
       */
      let res = await announcementUpdate({ id: this.id }, "get");
      this.formData = {
        ...this.formData,
        ...res.data,
      };
      this.checkedProject = res.data.project.project_ids_arr || [];
      this.imageUrl = res.data.thumb_path_source;

      // 赋值渲染百度编辑器内容
      this.$nextTick((_) => {
        this.$refs.editor1.setUeditorContent(res.data.content);
      });

      //请求返回成功后打开弹窗
      this.formDialogVisible = true;
    },
    /** 取消用户编辑 */
    handleCancle() {
      this.$refs["formData"].clearValidate();
      this.$router.go(-1);
    },

    handleSubmit() {
      console.log("this.checkedProject", this.checkedProject);
      this.formData = {
        ...this.formData,
        project_ids:
          this.checkedProject && this.checkedProject.length == 0
            ? ""
            : this.checkedProject.toString(),
        res_branch: this.formData.res_branch_arr.toString(),
        content: this.$refs.editor1.getUeditorContent(), //富文本编辑框数据赋值
      };
      console.log('formData',this.formData)
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          /**
           * 请求接口
           */
          // delete this.formData.delete_time;
          // delete this.formData.update_time;
          if (this.id) {
            // 存在id，执行编辑请求
            var res = await announcementUpdate(this.formData, "post");
          } else {
            // 不存在id，执行添加请求
            var res = await announcementAdd(this.formData);
          }
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            // 请求后，不论是否成功，调用方法关闭弹窗

            this.handleCancle();
          }
        }
      });
    },
    /** 上传前处理 */
    uploadBefore(file) {
      const isImg =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png" ||
        file.type == "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImg) {
        this.$message.error("上传图片只能是 .jpg,.jpeg,.png,.gif 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isImg && isLt2M;
    },
    /** 上传成功 */
    uploadSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.formData = {
        ...this.formData,
        thumb: res.data.list[0].id,
      };
    },
    /** 上传失败 */
    uploadError(err, file) {
      this.$message.warning("上传失败");
    },
  },
};
</script>
