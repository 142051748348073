import API from "@utils/request";

const ANNOUNCEMENT = "/index/";

/**
 * 公告列表
 * @param {object} params 
 * @returns 
 */
export async function getAnnouncementList(params) {
    try {
        return await API.post(ANNOUNCEMENT + "announcementList", params);
    } catch (error) {
        return error;
    }
}

/**
 * 编辑/回显
 * @param {object} params 
 * @param {string} type 
 * @returns 
 */
export async function announcementUpdate(params, type = 'get') {
    try {
        if (type == 'get') {
            return await API.get(ANNOUNCEMENT + "announcementUpdate", { params });
        } else {
            return await API.post(ANNOUNCEMENT + "announcementUpdate", params);
        }

    } catch (error) {
        return error;
    }
}
/**
 * 字段修改
 * @param {object} params 
 * @returns 
 */
 export async function announcementFieldUpdate(params) {
    try {
        return await API.post(ANNOUNCEMENT + "announcementFieldUpdate", params);
    } catch (error) {
        return error;
    }
}
/**
 * 新增
 * @param {object} params 
 * @returns 
 */
export async function announcementAdd(params) {
    try {
        return await API.post(ANNOUNCEMENT + "announcementAdd", params);
    } catch (error) {
        return error;
    }
}
/**
 * 删除
 * @param {string} id 
 * @returns 
 */
export async function announcementDelete(id) {
    try {
        return await API.post(ANNOUNCEMENT + "announcementDelete", {
            id: id,
        });
    } catch (error) {
        return error;
    }
}